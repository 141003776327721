import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';
import {
  faBriefcaseMedical,
  faHandFist,
  faShield,
  faShuffle,
  faVirus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const AFKGuidesTeams: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide '}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>AFK Progression Teams</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_team.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Progression Teams</h1>
          <h2>
            How to build teams in AFK Journey and what are the best teams you
            can run early in the game for AFK Stages progression.
          </h2>
          <p>
            Last updated: <strong>18/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          In this guide, we offer information about the basics of team building
          and examples of pre-made teams.{' '}
          <strong>
            Please note that these are just guidelines, and not hard rules
          </strong>
          . Due to the existence of several key factors — that we will go over
          in a minute — that somewhat restrict the ways teams can be built in
          AFK Journey. If you don’t see your preferred character in this guide,
          it doesn’t mean they’re not viable; in that case just refer to our
          Tier List or character page to see what place they have in teams.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Tier List"
            link="/afk-journey/tier-list"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_tier.jpg"
                alt="Tier List"
              />
            }
          />
        </Row>
        <SectionHeader title="Faction Bonus" />
        <StaticImage
          src="../../../images/afk/generic/faction_bonus.webp"
          alt="Guides"
        />
        <p>
          While creating a team, it is important to maximize your faction bonus.
          Whenever more than 2 characters of the same faction are put on a team,
          the entire team’s stats are increased by a certain percent. This means
          you’re incentivized to keep your teams to two factions at most, though
          the <AFKItem name="Celestial" /> and <AFKItem name="Hypogean" />{' '}
          factions are here to help mitigate the issue.
        </p>
        <p>
          <AFKItem name="Celestial" /> and <AFKItem name="Hypogean" /> are
          interesting, as they serve as ‘neutral’ characters that can count
          towards any faction. To make it easier to understand: if you run 4
          characters of the <AFKItem name="Wilders" /> faction, then add either
          a Celestial or Hypogean character, it’ll activate the 5 character
          matching faction bonus.
        </p>
        <p>
          <strong>
            Note that it’s not worth it to go for a mono-faction set-up
          </strong>
          . Not only do most factions not have 5 characters that both are good
          and synergize with one another, the extra stats are just not worth it
          when compared to the utility of some of the meta characters. Instead,
          most teams function on a ‘core’,{' '}
          <strong>which is a set of 3 characters of the same faction</strong>{' '}
          that dictate what faction the team will run around. The other two
          units are then added based on synergy or power.
        </p>
        <SectionHeader title="Standard Team Setup" />
        <p>
          Even though there are many possible teams in this game, more often
          than not, they will follow the same style of building when deciding
          between which characters to use.
        </p>
        <ul>
          <li>
            Pick a{' '}
            <strong className="tank">
              <FontAwesomeIcon icon={faShield} width="18" /> Tank
            </strong>
            . Tanks are the key figures in a team, even if their presence
            doesn’t often decide the other characters that will make up the
            team. Tanks have an exceedingly small roster, so be careful with who
            you pick to build around the faction bonus.
          </li>
          <li>
            Pick 1-2x{' '}
            <strong className="dps">
              <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
            </strong>
            . Their main task is to kill the enemies before they kill you.
          </li>
          <li>
            Pick 1-3x{' '}
            <strong className="support">
              <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
            </strong>
            . Supports are often the backbone of a team. Though most Supports
            (depending on the character) can provide enough sustain on their
            own, sometimes their kits trade raw healing output for things like
            buffs or debuffs.
          </li>
          <li>
            Flex spot. Pick a{' '}
            <strong className="specialist">
              <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
            </strong>
            or a second{' '}
            <strong className="tank">
              <FontAwesomeIcon icon={faShield} width="18" /> Tank
            </strong>
            .
          </li>
          <ul>
            <li>
              Specialists are either characters with quirky kits, or characters
              that focus on multiple roles at the same time. A key observation
              you may have is that the “good” Specialists often provide
              something in terms of buffs, debuffs or enhancements for your
              other characters,
            </li>
          </ul>
        </ul>
        <SectionHeader title="Team Suggestions" />
        <p>
          Team compositions typically feature a{' '}
          <strong>core of 3 units of the same faction</strong> (so you can
          activate the +10% stat bonus), with the other 2 being flexed either
          between another faction or non-matching units. Below you will find
          beginner core teams for Maulers and Wilders who are the most common
          ones you will use for AFK Pushing.
        </p>
        <SectionHeader title="Low Dupe Mauler Team" />
        <StaticImage
          src="../../../images/afk/teams/afk_team_1.webp"
          alt="Guides"
        />
        <p>
          If you are just starting on a new server and don’t have{' '}
          <AFKCharacter mode="inline" slug="smokey-and-meerky" enablePopover />{' '}
          at Mythic+ yet, it is possible to start with a team focused on sustain
          and dealing damage. The team works well at low dupes.
        </p>
        <div className="team-table">
          <div className="heroes">
            <div className="hero-column">
              <p className="role tank">
                <FontAwesomeIcon icon={faShield} width="18" /> Tank
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="antandra"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="smokey-and-meerky"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="koko"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="rowan"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="heroes two">
            <div className="hero-column">
              <p className="role dps">
                <FontAwesomeIcon icon={faHandFist} width="18" />
                Damage dealer
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="odie"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="lily-may"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role flex">
                <FontAwesomeIcon icon={faShuffle} width="18" /> Flex Spot
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="cecia"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="brutus"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="lucca"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="info">
            <h6>Information about the team members</h6>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="antandra" enablePopover /> is
                tanky even at low dupes and paradoxically works best as a
                frontliner before Mythic+. Best used with a second frontline
                character to buff with her Shield.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="cecia" enablePopover /> is a
                guaranteed drop from the tutorial summons and she can be used as
                a frontliner thanks to Antandra’s shield (and taking damage also
                charges up her ultimate faster).{' '}
                <AFKCharacter mode="inline" slug="brutus" enablePopover /> is a
                one-dupe wonder that can often buy enough time for your dps
                characters to get some damage in, and for supports to charge up
                their ultimates. Last but not least,{' '}
                <AFKCharacter mode="inline" slug="lucca" enablePopover /> can
                also be used as a second frontliner thanks to being quite tanky
                even at low dupes.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover /> works
                best for our faction bonus, but typically wants Mythic+ to
                unlock his full potential. If you can make the faction bonus
                work using the flex slot, consider running{' '}
                <AFKCharacter mode="inline" slug="lily-may" enablePopover />{' '}
                instead as she is one of the best dps carries overall.
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                while not providing the damaging AOE before Mythic+, can still
                provide consistent healing and buffs as long as you can protect
                them from being interrupted. If interruptions are a concern,
                consider running{' '}
                <AFKCharacter mode="inline" slug="koko" enablePopover /> instead
                as she can provide a lot of sustain with Rowan to speed up her
                ultimate.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="rowan" enablePopover /> is
                used as an Energy Regen source to speed up ultimates while also
                offering some healing.
              </li>
            </ul>
          </div>
        </div>
        <SectionHeader title="Mythic Mauler Team (Smokey Team)" />
        <StaticImage
          src="../../../images/afk/teams/afk_team_2.webp"
          alt="Guides"
        />
        <p>
          The typical Mauler AFK pushing team is built around{' '}
          <AFKCharacter mode="inline" slug="smokey-and-meerky" enablePopover />,
          whose ultimate can kill almost any enemy as long as they are kept
          inside the ultimate’s radius, taking true HP% damage. Requires an
          investment of at least Mythic+ to unlock this ability. The rest of the
          team offers a lot of sustain and utility to help gather enemies for
          Smokey & Meerky to finish off.
        </p>
        <div className="team-table">
          <div className="heroes">
            <div className="hero-column">
              <p className="role tank">
                <FontAwesomeIcon icon={faShield} width="18" /> Tank
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="phraesto"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="smokey-and-meerky"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="rowan"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="heroes two">
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="koko"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role flex">
                <FontAwesomeIcon icon={faShuffle} width="18" /> Flex Spot
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="nara"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="lumont"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="info">
            <h6>Information about the team members</h6>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover />{' '}
                offering two bodies to soak up damage and Energy Regen for the
                team, is the best pick for the Tank slot.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="nara" enablePopover /> is a
                one dupe wonder who serves great utility in AFK pushing by
                bringing enemies within Smokey’s ultimate range and executing
                low health enemies. Alternatively,{' '}
                <AFKCharacter mode="inline" slug="lumont" enablePopover /> can
                serve the same purpose of gathering enemies.
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                is the core of the team, and main source of damage, while also
                healing and providing buffs to the team.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="rowan" enablePopover /> is
                perfect for this ultimate-based team as he serves the role of
                accelerating ally ultimates, quickly advancing Smokey through
                the stages of their ultimate and also allowing Koko to get her
                ultimate out as soon as possible.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="koko" enablePopover />{' '}
                provides more staying power with her ultimate’s Damage Reduction
                buff, making this team very hard to kill if she gets her
                ultimate off.
              </li>
            </ul>
            <p>Other options:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="antandra" enablePopover /> or{' '}
                <AFKCharacter mode="inline" slug="brutus" enablePopover /> -
                While Phraesto is the best pick for tank, we can use Brutus or
                Antandra as a replacement while also keeping the faction bonus.
                But make sure to bring a second tank in the Flex Position such
                as Thoran, Lucca, or even Lucius, because Brutus does not last
                long in the frontline, and Antandra typically works best as an
                off-tank.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover /> - In
                stages where we don’t need all the sustain of the standard
                formation, Koko can be replaced by Odie for more damage, and to
                help kill stragglers that didn’t get pulled into Smokey’s
                ultimate.
              </li>
            </ul>
          </div>
        </div>
        <SectionHeader title="Mythic Lightbearer Team (Hugin-Odie Team)" />
        <StaticImage
          src="../../../images/afk/teams/afk_team_4.webp"
          alt="Guides"
        />
        <p>
          A team taking advantage of the Lightbearer faction’s sturdiest
          characters along with the talent tile’s Hogan summon to hold the line
          and support a high damage carry.
        </p>
        <div className="team-table">
          <div className="heroes">
            <div className="hero-column">
              <p className="role tank">
                <FontAwesomeIcon icon={faShield} width="18" /> Support
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="hugin"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="rowan"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Flex
                Slot
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="sonja"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="koko"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="hewynn"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="heroes two">
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> DPS
                Carry
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="odie"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role flex">
                <FontAwesomeIcon icon={faShuffle} width="18" /> Tank
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="lucca"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="lucius"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="info">
            <h6>Information about the team members</h6>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="hugin" enablePopover /> offers
                ATK and HASTE buffs, shields, and for the ally behind him, he
                also provides Energy Regen.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="rowan" enablePopover /> helps{' '}
                <AFKCharacter mode="inline" slug="hugin" enablePopover /> and
                everyone else cycle ultimates faster, making the team harder to
                kill and further increasing damage output.
              </li>
              <li>
                For the flex slot, typically we bring yet another support.{' '}
                <AFKCharacter mode="inline" slug="hewynn" enablePopover />{' '}
                offers the most raw healing,{' '}
                <AFKCharacter mode="inline" slug="koko" enablePopover /> offers
                more buffs and damage resistance,{' '}
                <AFKCharacter mode="inline" slug="lorsan" enablePopover />{' '}
                offers burst healing and damage duplication.{' '}
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                offers the true damage and sustain the Smokey team is known for.
                We can also just bring another DPS carry if{' '}
                <AFKCharacter mode="inline" slug="odie" enablePopover /> alone
                is not cutting it.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover /> is
                important to this team because unlike most characters who wander
                around, Odie remains in place, taking full advantage lf Hugin’s
                buffs and Energy Regen on the tile etched behind Hugin.
              </li>
              <li>
                For the tank slot, we want another Lightbearer to close out the
                requirement for the tile.{' '}
                <AFKCharacter mode="inline" slug="lucca" enablePopover /> is the
                best of the two, but{' '}
                <AFKCharacter mode="inline" slug="lucius" enablePopover /> can
                also work well thanks to{' '}
                <AFKCharacter mode="inline" slug="rowan" enablePopover />{' '}
                allowing him to spam his shield ultimate.
              </li>
            </ul>
          </div>
        </div>
        <SectionHeader title="Mythic Wilder Team (Eironn Team)" />
        <StaticImage
          src="../../../images/afk/teams/afk_team_3.webp"
          alt="Guides"
        />
        <p>
          The second major AFK Pushing archetype is the{' '}
          <AFKCharacter mode="inline" slug="eironn" enablePopover /> team, based
          around locking down the enemy using the powerful Eironn + Ulmus combo
          and taking advantage of allies with CC synergy and high damage output.
          Does not typically run sustain.{' '}
          <strong>
            Requires Mythic+ to unlock Eironn’s instant ultimate which best
            enables the archetype.
          </strong>
        </p>
        <div className="team-table">
          <div className="heroes">
            <div className="hero-column">
              <p className="role tank">
                <FontAwesomeIcon icon={faShield} width="18" /> Tank
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="ulmus"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role specialist">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                Specialist
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="eironn"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role dps">
                <FontAwesomeIcon icon={faHandFist} width="18" />
                Damage dealer
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="carolina"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="arden"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="heroes two">
            <div className="hero-column">
              <p className="role dps">
                <FontAwesomeIcon icon={faHandFist} width="18" />
                Damage dealer
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="bonnie"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="parisa"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role flex">
                <FontAwesomeIcon icon={faShuffle} width="18" /> Flex Spot
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="lorsan"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="temesia"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="rowan"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="tasi"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="info">
            <h6>Information about the team members</h6>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="ulmus" enablePopover /> takes
                advantage of Eironn’s ultimate to root enemies in a tight
                cluster, setting them up to take more damage.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="eironn" enablePopover /> is
                the core of the team, and ensures enemies are stunned and
                grouped up right away.
              </li>
              <li>
                For damage carries, typically we want those who synergize with
                crowd control, and as far as raw damage output goes,{' '}
                <AFKCharacter mode="inline" slug="carolina" enablePopover /> and{' '}
                <AFKCharacter mode="inline" slug="arden" enablePopover /> are
                some of the best in CC teams. You can use either one of them, or
                even both.
              </li>
              <li>
                The secondary damage dealer can be someone who enables the other
                damage carries, such as{' '}
                <AFKCharacter mode="inline" slug="bonnie" enablePopover />, who
                has crowd control built into her kit along with decently high
                damage, or a specialist like
                <AFKCharacter mode="inline" slug="parisa" enablePopover />, who
                can buff the other damage dealer and also deal devastating
                damage to the tight group of enemies with her attacks, or any
                other damage dealer who synergizes with crowd control.
              </li>
              <li>
                For the flex slot, there are many options. We can bring{' '}
                <AFKCharacter mode="inline" slug="temesia" enablePopover /> to
                keep enemies busy while also dealing damage; we can also bring{' '}
                <AFKCharacter mode="inline" slug="rowan" enablePopover /> to
                speed up our damage carries ultimates. We can also bring{' '}
                <AFKCharacter mode="inline" slug="tasi" enablePopover /> to
                deliver her powerful ultimate while the enemy remains stunned,
                among other options.
              </li>
            </ul>
            <p>Other options:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="lenya" enablePopover />{' '}
                <AFKCharacter mode="inline" slug="alsa" enablePopover /> or{' '}
                <AFKCharacter mode="inline" slug="florabelle" enablePopover /> -
                All of these damage carries also bring their own CC to the table
                and can be slotted in, especially at lower deficit, but
                typically aren’t the best options for high deficit AFK pushing,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="lorsan" enablePopover /> -
                brings more damage, buff and some sustain,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="damian" enablePopover /> - If
                you find yourself needing a little bit of extra sustain,
                consider slotting in Damian in the flex slot for some safe
                healing and Haste buff.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesTeams;

export const Head: React.FC = () => (
  <Seo
    title="AFK Progression Teams | AFK Journey | Prydwen Institute"
    description="How to build teams in AFK Journey and what are the best teams you can run early in the game for AFK Stages progression."
    game="afk"
  />
);
